import { Form, redirect, useLoaderData } from "react-router-dom";
import { Box, Button, Sheet, Table, Typography } from "@mui/joy";
import { deleteUser, getUsers } from "../Users";
export async function loader(){
    const Users = await getUsers();
    return {Users}
} 
export async function action({request}){
    const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await deleteUser(updates);
        return redirect('/administration/users');
}  
export default function Users(){
    const data = useLoaderData();
    const Users = data.Users.hasOwnProperty('records')?data.Users.records:[];
    return(
        <Box>
            <Typography level="h1">Brugere</Typography>
            <Sheet>
                <Table >
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Email</th>
                            <th>Handlinger</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Users.map((user)=>{return(
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td><Box sx={{display:"flex",gap:"1rem"}}><Button component="a" href={"/administration/editUser/"+user.id}>rediger</Button><Form method="post"><input type="hidden" name="id" value={user.id}/><Button type="submit">slet</Button></Form></Box></td>
                        </tr>)})}
                    </tbody>
                </Table>
            </Sheet>
            <Button component="a" href="/administration/newUser">Opret Ny bruger</Button>
        </Box>
    );
}