import { Box, Button, FormControl, FormLabel, Input, Typography } from "@mui/joy";
import { Form, redirect, useLoaderData } from "react-router-dom";
import { getUser, updateUser } from "../Users";
import { useState } from "react";

export async function action({request}){
    const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await updateUser(updates);
        return redirect('/administration/users');
}

export async function loader({params}){
        const User = await getUser(params.userId);
        return { User };
}
export default function EditUser(){
    const data=useLoaderData();
    const user= data.User;
    const [name,setName]=useState(user.name);
    const [email,setEmail]=useState(user.email);
    const [password,setPassword]=useState("");

    return(
        <Box display="flex" flexDirection="column" gap="2rem"><Typography level="h1">Rediger Bruger</Typography>
            <Form method="post">
                <FormControl>
                    <FormLabel>Navn</FormLabel>
                    <Input name="name" onChange={(e)=>setName(e.target.value)} value={name}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" name="email" onChange={(e)=>setEmail(e.target.value)} value={email}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} name="password" />
                </FormControl>
                <FormControl sx={{marginTop:"2rem"}}>
                    <Button type="submit">Opdater Bruger</Button>
                </FormControl>
            </Form>
        </Box>
    )
}