import { Button, List, ListItem, ListSubheader, Sheet, Typography } from "@mui/joy";
import { Form } from "react-router-dom";

export default function SupplyDayList({name, date, categories, courses, dayBookings,daySupply}){
    let blockedCourses = [];
    let bookedCourses = [];
    if(Array.isArray(dayBookings)){
      dayBookings.forEach(booking=>{blockedCourses.push(courses.find(cour=>cour.id===booking.course_id).blocks);bookedCourses.push(booking.course_id);blockedCourses.push(booking.course_id)})
    }
    blockedCourses = blockedCourses.flat();
    bookedCourses = bookedCourses.flat();
    let daySupplyCourseIds = [];
    if(Array.isArray(daySupply)){
        daySupply.forEach(supply => daySupplyCourseIds.push(supply.course_id))
    }

    return(
        <Sheet variant="outlined" sx={{p:4,backgroundColor:"burlywood"}}>
            <Typography variant="solid" sx={{p:2}}>{name}</Typography>
            <List size="sm" sx={{paddingBlock:0}} className="courselist">
                {Array.isArray(categories)?categories.map((cat)=>
                <ListItem key={cat.id} nested>
                    <ListSubheader><Typography level="h5" color="primary">{cat.title}</Typography></ListSubheader>
                    <List size="sm">
                        {courses.filter(course=>course.category_id === cat.id).map((course)=> 
                        <ListItem key={course.id} endAction={blockedCourses.indexOf(course.id)===-1?
                            daySupplyCourseIds.indexOf(course.id)===-1?
                                <Form method="post">
                                    <input type="hidden" name="supplyAction" value="create"/>
                                    <input type="hidden" name="course_id" value={course.id} />
                                    <input type="hidden" name="date" value={date}/>
                                    <input type="hidden" name="category_id" value={course.category_id}/>
                                    <Button color="success" size="sm" type="submit">udbyd</Button>
                                </Form>:
                                <Form method="post">
                                    <input type="hidden" name="supplyAction" value="delete"/>
                                    <input type="hidden" name="id" value={daySupply.find(supply=>supply.course_id===course.id).id}/>
                                    <Button color="warning" size="sm" type="submit">annuller</Button>
                            </Form>:
                            <Button size="sm" disabled >{bookedCourses.indexOf(course.id)===-1?"blokeret":"booked"}</Button>}><Typography variant={bookedCourses.indexOf(course.id)===-1?"plain":"outlined"}>{course.short_name}</Typography>
                        </ListItem>)}
                    </List>
                </ListItem>):""}
            </List>
        </Sheet>
    )

}