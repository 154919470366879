import { Box, Button, List, ListItem, ListSubheader, Typography } from "@mui/joy";
import { getCategories } from "../Categories";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { getCourses } from "../Courses";
import { validateAuth } from "../Authenticator";

export async function loader(){
    const Categories = await getCategories();
    const Courses = await getCourses();
    const Authenticated = await validateAuth();
    return {Categories,Courses,Authenticated};
}

export default function Root(){
    const data = useLoaderData();
    const navigate = useNavigate();
    function handleAdmin(){
        navigate("/administration");
    }

    const categories = data.Categories.hasOwnProperty('records')?data.Categories.records:[];
    const courses = data.Courses.hasOwnProperty('records')?data.Courses.records:[];
    return (
        <Box display="flex" height="100vh" alignItems={"stretch"}>
            <Box display="flex"  p={4} flexDirection={"column"} sx={{backgroundColor:"lightgrey"}} width="15%" >
            <List>
            {categories.map((cat)=><ListItem key={cat.id} nested><ListSubheader>{cat.title}</ListSubheader>
            <List>
            {courses.filter(course=>course.category_id === cat.id).map((course)=> <ListItem key={course.id} component="a" href={"/forloeb/"+course.id}>{course.title}</ListItem>)}</List></ListItem>)}
        </List>
                <Box display="flex">
                <Button component="a" onClick={handleAdmin}>Administration</Button>
                </Box>
            <Box
          sx={{
            display: 'flex',
            gap: 1,
            p: 1.5,
            pb: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
            <Typography level="title-md">Utzon Center Booking</Typography>
        </Box>
            </Box>
            <Box display="flex" width="85%" sx={{background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)'}}><Outlet/></Box>
        </Box>
    );
}