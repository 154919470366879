import { BookmarkAdd } from "@mui/icons-material";
import { AspectRatio, Box, Button, Card, CardContent, CardCover, Grid, IconButton, Typography } from "@mui/joy";
export default function Index() {
    return (
        <Box p={10}>
      <Typography level="h1">
        Velkommen til Utzon Centers bookingside
        </Typography>
        <Typography>
        Vælg et forløb i venstre side for at begynde din booking
        </Typography>
        <Grid container spacing={2} sx={{flexGrow:1}}>
          <Grid xs={4}>
            <Card>
       
              <AspectRatio minheight="120px" maxHeight="200px">
              
              </AspectRatio>
              <CardCover><video
                poster="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2"
                autoPlay
                loop
                muted>
                <source
                src="https://arkitekturerenleg.dk/wp-content/uploads/2021/11/Sequence-01_1.mp4"
                type="video/mp4"/>
                </video></CardCover>
                <CardCover
        sx={{
          background:
            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
        }}
      />
              <CardContent orientation="horizontal"  sx={{ justifyContent: 'flex-end' }}>
                <div>
              <Typography level="title-lg" textColor="#fff">I skalaens verden</Typography>
              <Typography level="body-sm">tir ons tor - 9-12.30 - 1250 kr</Typography>
              </div>
                  <Button
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Book I skalaens verden"
                  sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600}}
                  >
                    Book en dag
                  </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={4}>
          <Card>
              <div>
                <Typography level="title-lg">Ultra:bit - Rum og Lys</Typography>
                <Typography level="body-sm">tir ons tor - 9-14 - 1750 kr</Typography>
                <IconButton aria-label="bookmark I skalaens Verden"
                variant="plain" color="neutral" size="sm" sx={{position: 'absolute',top:'0.874remn', right: '0.5rem'}}>
                  <BookmarkAdd/>
                </IconButton>
              </div>
              <AspectRatio minheight="120px" maxHeight="200px">
                <img
                src="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"
                srcSet="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2 2x"
                loading="lazy"
                alt=""/>
              </AspectRatio>
              <CardContent orientation="horizontal">
                <div>
                  <Typography level="body-xs">Total price:</Typography>
                  <Typography fontSize="lg" fontWeight="lg">Dkk 1.750</Typography>
                  </div>
                  <Button
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Book I skalaens verden"
                  sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600}}
                  >
                    Book en dag
                  </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={4}>
            <Card>
              <div>
                <Typography level="title-lg">I skalaens verden</Typography>
                <Typography level="body-sm">tir ons tor - 9-12.30 - 1250 kr</Typography>
                <IconButton aria-label="bookmark I skalaens Verden"
                variant="plain" color="neutral" size="sm" sx={{position: 'absolute',top:'0.874remn', right: '0.5rem'}}>
                  <BookmarkAdd/>
                </IconButton>
              </div>
              <AspectRatio minheight="120px" maxHeight="200px">
                <img
                src="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"
                srcSet="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2 2x"
                loading="lazy"
                alt=""/>
              </AspectRatio>
              <CardContent orientation="horizontal">
                <div>
                  <Typography level="body-xs">Total price:</Typography>
                  <Typography fontSize="lg" fontWeight="lg">Dkk 1.250</Typography>
                  </div>
                  <Button
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Book I skalaens verden"
                  sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600}}
                  >
                    Book en dag
                  </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid xs={4}>
          <Card>
              <div>
                <Typography level="title-lg">Ultra:bit - Rum og Lys</Typography>
                <Typography level="body-sm">tir ons tor - 9-14 - 1750 kr</Typography>
                <IconButton aria-label="bookmark I skalaens Verden"
                variant="plain" color="neutral" size="sm" sx={{position: 'absolute',top:'0.874remn', right: '0.5rem'}}>
                  <BookmarkAdd/>
                </IconButton>
              </div>
              <AspectRatio minheight="120px" maxHeight="200px">
                <img
                src="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"
                srcSet="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2 2x"
                loading="lazy"
                alt=""/>
              </AspectRatio>
              <CardContent orientation="horizontal">
                <div>
                  <Typography level="body-xs">Total price:</Typography>
                  <Typography fontSize="lg" fontWeight="lg">Dkk 1.750</Typography>
                  </div>
                  <Button
                  variant="solid"
                  size="md"
                  color="primary"
                  aria-label="Book I skalaens verden"
                  sx={{ml: 'auto', alignSelf: 'center', fontWeight: 600}}
                  >
                    Book en dag
                  </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
      </Box>
    );
  }