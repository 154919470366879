const setCookie=(cname,cvalue,exdays)=>{
    let d = new Date();
    d.setTime(d.getTime()+(exdays*24*60*60*1000));
    let expires = "expires" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
const getCookie =(cname)=>{
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++){
        var c = ca[i];
        while (c.charAt(0)===' '){
            c=c.substring(1);
        }

        if(c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export async function validateAuth(){
    let isAuthenticated =false;
    function handleFetchErrors(response){
        if(!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    
    let jwt = getCookie("jwt");
    if(jwt!=="" && jwt !== undefined && isAuthenticated===false){
        await fetch("https://api.uc-booking.dk/validate_token.php",{
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                jwt:jwt
            })
        }).then(handleFetchErrors).then(response=>{isAuthenticated=true}).catch(error=>{console.log("invalid token"); isAuthenticated=false;setCookie("jwt","",1)})
    }
    return(isAuthenticated);
}