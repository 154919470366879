import { Box, Button, List, ListItem, ListSubheader, Typography } from "@mui/joy";
import { useLoaderData } from "react-router-dom";
import { getCategories } from "../Categories";
import { getCourses } from "../Courses";

export async function loader(){
    const Categories = await getCategories();
    const Courses = await getCourses();
    return {Categories,Courses}
}   
export default function Courses(){
    const data = useLoaderData();
    const categories = data.Categories.hasOwnProperty('records')?data.Categories.records:[];
    const courses = data.Courses.hasOwnProperty('records')?data.Courses.records:[];
    return(
        <Box>
        <Typography level="h1">Forløb</Typography>
        <List>
            {categories.map((cat)=><ListItem key={cat.id} nested><ListSubheader key={cat.id}>{cat.title}</ListSubheader>
            <List>
            {courses.filter(course=>course.category_id === cat.id).map((course)=> <ListItem key={course.id} component="a" href={"/administration/editCourse/"+course.id}>{course.title}</ListItem>)}</List></ListItem>)}
        </List>
        <Button component="a" href="/administration/newCourse">Opret Forløb</Button>
        </Box>

    );
}