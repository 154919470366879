function getCookie(cname){
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' '){
            c = c.substring(1);
        }
  
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }
  export async function getSupplys(){
    let jwt = getCookie("jwt");
      const response = await fetch(
          `https://api.uc-booking.dk/supply/read.php`,{method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            jwt:jwt
          })});
        return await response.json();
      };
  
  export async function getSupply(supId){
    const response = await fetch(
      `https://api.uc-booking.dk/supply/readne.php`,{method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id:supId,
        })});
    return await response.json();
  };
  export async function createSupply(supDetails){
    let jwt = getCookie("jwt");
    const response = await fetch(`https://api.uc-booking.dk/supply/create.php`,
    {method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        date:supDetails.date,
        course_id:supDetails.course_id,
        booking_id:supDetails.booking_id,
        jwt:jwt
      })})
    return await response.json();
  
  }
  export async function updateSupply(supDetails){
    let jwt = getCookie("jwt");
      const response = await fetch(`https://api.uc-booking.dk/supply/update.php`,
          {method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id:supDetails.id,
              date:supDetails.date,
              course_id:supDetails.course_id,
              booking_id:supDetails.booking_id,
              jwt:jwt
            })})
    return await response.json();
  }
  export async function deleteSupply(supDetails){
    let jwt = getCookie("jwt");
    const response = await fetch(`https://api.uc-booking.dk/supply/delete.php`,
          {method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id:supDetails.id,
              jwt:jwt
            })})
            return await response.json();
  }