import { Box, Button, Checkbox, FormControl, FormHelperText, FormLabel, Input, List, ListItem, Option, Select, Typography } from "@mui/joy";
import { Form, redirect, useLoaderData } from "react-router-dom";
import { getCategories } from "../Categories";
import { createCourse, getCourses } from "../Courses";
import Done from '@mui/icons-material/Done';
import { useState } from "react";

export async function loader(){
    const Categories = await getCategories();
    const Courses = await getCourses();

    return {Categories, Courses}
}

export async function action({request}){
        const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await createCourse(updates);
        return redirect('/administration/courses');
}

export default function NewCourse(){
    const data= useLoaderData();
    const Categories = data.Categories.records;
    const Courses = data.Courses.records;
    const [value,setValue]=useState([]);
    const [allowMultipleTimeslots,setAllowMultipleTimeslots] = useState("0");
    return(
    <Box>
    <Typography>Opret Nyt Forløb</Typography>
    <Form method="post">
        <FormControl>
            <FormLabel>Titel</FormLabel>
            <Input name="title" />
        </FormControl>
        <FormControl>
            <FormLabel>Kort navn</FormLabel>
            <Input name="short_name" />
        </FormControl>
        <FormControl>
            <FormLabel>Kategori</FormLabel>
            <Select name="category_id">
                {Array.isArray(Categories)?Categories.map((category)=>{return(<Option key={category.id} value={category.id}>{category.title}</Option>)}):<Option value={""}>ingen kategori</Option>}
                </Select>
        </FormControl>
        {Array.isArray(Courses)?<FormControl>
            <FormLabel>Blokerer</FormLabel>
            <List
            orientation="horizontal"
            wrap
            sx={{
              '--List-gap': '8px',
              '--ListItem-radius': '20px',
              '--ListItem-minHeight': '32px',
              '--ListItem-gap': '4px',
            }}>
            {Courses.map(
                (course,index)=>(
                <FormControl key={course.id}><ListItem key={course.id}>
                    {value.includes(course.title) && (
                        <Done
                            fontSize="md"
                            color="primary"
                            sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                        />
                    )}
                  <Checkbox 
                  size="sm"
                  disableIcon
                  overlay
                  label={course.title}
                  checked={value.includes(course.title)}
                  variant={value.includes(course.title)? 'soft' : 'outlined'}
                  onChange={(event)=>{
                    if(event.target.checked){
                        setValue((val)=> [...val,course.title]);
                    }else {
                        setValue((val)=>val.filter((text)=>text!== course.title));
                    }
                  }}
                  slotProps={{
                    action:({checked})=>({
                        sx: checked
                        ? {
                            border: '1px solid',
                            borderColor: 'primary.500',
                        }
                        :{},
                    }),
                  }}
                  />
                  </ListItem></FormControl>
                  ),
                  )}
                  </List>
            <input type="hidden" value={value} name="blocks"/>
        </FormControl>:<FormControl><Typography>Blokerer</Typography><Typography>ingen andre forløb oprettet</Typography><input type="hidden" value={value} name="blocks"/></FormControl>}        
        <FormControl>
            <FormLabel>Tidspunkter</FormLabel>
            <FormHelperText>Adskild tidsrum med komma eks. 10.00-13.00,13.00-17.00,8.00-10.00</FormHelperText>
            <Input name="time_slots"/>
        </FormControl>
        <FormControl>
            <FormLabel>Tillad booking af flere tidspunkter</FormLabel>
            <Checkbox checked={allowMultipleTimeslots==="0"?false:true} value={allowMultipleTimeslots} name="allow_multiple_timeslots" onChange={(e)=>setAllowMultipleTimeslots(e.target.checked?"1":"0")}/>
        </FormControl>
        <input type="hidden" name="allow_multiple_timeslots" value={allowMultipleTimeslots}/>
        <FormControl>
            <FormLabel>Pris</FormLabel>
            <Input type="number" name="price" />
        </FormControl>
        <FormControl>
            <FormLabel>Link</FormLabel>
            <Input name="link" />
        </FormControl>
        <FormControl orientation="horizontal" sx={{marginTop:"2rem",width: 300, justifyContent: 'space-between'}}>
            <Button type="submit">Opret Forløb</Button>
        </FormControl>
    </Form>
    </Box>
    )
}