import { Box, Button, List, ListItem, Typography } from "@mui/joy";
import {  Outlet, redirect, useLoaderData, useNavigate } from "react-router-dom";
import { validateAuth } from "../Authenticator";

export async function loader(){
    const Authenticated = await validateAuth();
    if(!Authenticated){
        return redirect("/login");
    }
    return {Authenticated};
}

export default function Administration(){
    const data = useLoaderData();
    const isAuth = data.Authenticated;
    const navigate = useNavigate();
    
    function logOut(){
        let d = new Date();
        d.setTime(d.getTime()+(1*24*60*60*1000));
        let expires = "expires" + d.toUTCString();
        document.cookie = "jwt=;" + expires + ";path=/";
        navigate("/");
    }

    return (
        <Box display="flex" height="100vh" alignItems={"stretch"}>
            <Box display="flex"   p={4} flexDirection={"column"} sx={{backgroundColor:"lightgrey"}} width="15%" >
            <List>
                <ListItem component="a" href="/administration/bookings">Bookinger</ListItem>
                <ListItem  component="a" href="/administration/users">Brugere</ListItem>
                <ListItem component="a" href="/administration/supply">Udbud</ListItem>
                <ListItem component="a" href="/administration/courses">Forløb</ListItem>
                <ListItem component="a" href="/administration/categories">Kategorier</ListItem>
                </List>
                <Box display="flex">
                <Button onClick={isAuth?logOut:navigate("/")}>Log ud</Button>
                </Box>
            <Box
          sx={{
            display: 'flex',
            gap: 1,
            p: 1.5,
            pb: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
          }}
        >
            <Typography level="title-md">Utzon Center Booking</Typography>
        </Box>
            </Box>
            <Box display="flex" padding="2.5%" width="80%" sx={{background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)'}}><Outlet/></Box>
        </Box>
    );
}