import { useRouteError } from "react-router-dom";
import {Typography} from "@mui/joy"

export default function BookingErrors() {
  const error = useRouteError();
  console.error(error.message);
if(error.message==="no supply for course"){
    return (<div id="error-page">
        <Typography level="h2">Forløbet er ikke tilgængeligt for booking</Typography>
    </div>);
}else if(error.message==="all booked"){
    return (<div id="error-page">
    <Typography level="h2">Forløbet er fuldt booket</Typography>
</div>);
}else{
  return (
    <div id="error-page">
      <h1>Der er opstået en uventet fejl</h1>
      <p>Det ser ud til at bookingformularen har en teknisk fejl</p>
      <p>kontakt leg og læring på mail: studio@utzoncenter.dk</p>
      <p>eller telefon: 76 90 50 00 spørg efter leg og læring</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
}