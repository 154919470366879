import { Form, redirect, useLoaderData } from "react-router-dom";
import { getCategory, updateCategory } from "../Categories";
import { Box, Button, FormControl, FormLabel, Input, Typography } from "@mui/joy";
import { useState } from "react";

export async function loader({params}){
    const category = await getCategory(params.categoryId);
    return {category};
}

export async function action({request}){
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    await updateCategory(updates);
    return redirect("/administration/categories");
}

export default function EditCategory(){
    const data = useLoaderData();
    const category = data.category;
    const [title,setTitle] = useState(category.title);
    return(
        <Box>
            <Typography level="h1">Rediger Kategori</Typography>
        
        <Form method="post">
            <FormControl><input type="hidden" value={category.id} name="id"/></FormControl>
            <FormControl>
            <FormLabel>Titel</FormLabel>
            <Input value={title} onChange={(e)=>setTitle(e.target.value)} name="title"/>
            </FormControl>
            <FormControl>
                <Button type="submit">Opdater Kategori</Button>
            </FormControl>
        </Form>
        </Box>
    )
}