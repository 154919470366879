import { Box, Button, FormControl, FormLabel, Input, Typography } from "@mui/joy";
import { Form, redirect } from "react-router-dom";
import { createCategory } from "../Categories";

export async function action({request}){
        const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await createCategory(updates);
        return redirect('/administration/categories');
}
export default function NewCategory(){

    return(
        <Box display="flex" flexDirection="column" gap="2rem">
        <Typography level="h1">Opret ny kategori</Typography>
        <Form method="post">
            <FormControl>
                <FormLabel>Titel</FormLabel>
                <Input name="title"></Input>
            </FormControl>
            <FormControl>
                <Button type="submit">Opret Kategori</Button>
            </FormControl>
        </Form>
        </Box>        
    )
}
