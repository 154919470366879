function getCookie(cname){
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' '){
            c = c.substring(1);
        }
  
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }
export async function getBookings(){
    let jwt = getCookie("jwt");
    const response = await fetch(
        `https://api.uc-booking.dk/booking/read.php`,{method: 'POST',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
            jwt:jwt
            })})
    return await response.json();
    };

export async function getBooking(bookingId){
    let jwt = getCookie("jwt");
    const response = await fetch(
        `https://api.uc-booking.dk/booking/readOne.php`,{method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id:bookingId,
            jwt:jwt
        })});
    return await response.json();
};
export async function createBooking(bookingDetails){
    let jwt = getCookie("jwt");
    const response = await fetch(`https://api.uc-booking.dk/booking/create.php`,
    {method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            date:bookingDetails.date,
            name:bookingDetails.name,
            email:bookingDetails.email,
            phone:bookingDetails.phone,
            school:bookingDetails.school,
            grade:bookingDetails.grade,
            participants:bookingDetails.participants,
            comment:bookingDetails.comment,
            course_id:bookingDetails.course_id,
            time_slots:bookingDetails.time_slots,
            billable:bookingDetails.billable,
            payment_method:bookingDetails.payment_method,
            payment_details:bookingDetails.payment_details,
        jwt:jwt
        })})
    return await response.json();

}
export async function updateBooking(bookingDetails){
    let jwt = getCookie("jwt");
    const response = await fetch(`https://api.uc-booking.dk/booking/update.php`,
        {method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id:bookingDetails.id,
            date:bookingDetails.date,
            name:bookingDetails.name,
            email:bookingDetails.email,
            phone:bookingDetails.phone,
            school:bookingDetails.school,
            grade:bookingDetails.grade,
            participants:bookingDetails.participants,
            comment:bookingDetails.comment,
            course_id:bookingDetails.course_id,
            time_slots:bookingDetails.time_slots,
            billable:bookingDetails.billable,
            payment_method:bookingDetails.payment_method,
            payment_details:bookingDetails.payment_details,
            jwt:jwt
        })})
    return await response.json();
}
export async function deleteBooking(bookingDetails){
    let jwt = getCookie("jwt");
    const response = await fetch(`https://api.uc-booking.dk/booking/delete.php`,
        {method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id:bookingDetails.id,
            jwt:jwt
        })})
    return await response.json();
}