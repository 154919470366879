import { Sheet, FormControl, FormLabel, Input, Typography, Button } from "@mui/joy"
import { Form, redirect } from "react-router-dom"
import { loginUser } from "../Users";

export async function action({request}) {

    const formData = await request.formData();
        const signInfo = Object.fromEntries(formData);
    const response = await loginUser(signInfo);
    let d = new Date();
      d.setTime(d.getTime()+(1*24*60*60*1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = "jwt=" + response.jwt + ";" + expires + ";path=/";
    return redirect(`/administration`);
  }

export default function Login() {
    
    return (
        <div id="Login">
            <Sheet
    sx={{
    width: 300,
    mx: 'auto', // margin left & right
            my: 4, // margin top & bottom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRadius: 'sm',
            boxShadow: 'md',
        }}
        variant="outlined">
            <div>
                <Typography level="h4" component="h1">
                    <b>Velkommen</b>
                </Typography>
                <Typography level="body-sm">Log på for at fortsætte.</Typography>
            </div>
            <Form method="post">
            <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              // html input attribute
              name="email"
              type="email"
              placeholder="johndoe@email.com"
            />
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              // html input attribute
              name="password"
              type="password"
              placeholder="password"
            />
          </FormControl>
          <Button type="submit" sx={{ mt: 1 /* margin top */ }}>Log in</Button>
          </Form>
        </Sheet>
        </div>
    )
}