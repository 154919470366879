import { redirect, useLoaderData, useSubmit } from "react-router-dom";
import { getCourse } from "../Courses";
import { getSupplys} from "../Supply";
import { getBookings, createBooking} from "../Bookings";
import { Box, Button, Checkbox, Chip, Grid, FormControl, FormHelperText, FormLabel, Input, List, ListDivider, ListItem, ListItemDecorator, ListSubheader, Option, Select, Sheet, Stack, Step, StepIndicator, Stepper, Typography, listItemDecoratorClasses, optionClasses, RadioGroup, Radio } from "@mui/joy";
import React, { useState } from "react";
import { getDay, getWeek, getYear } from "date-fns";
import { Check } from "@mui/icons-material";
import { getCategory } from "../Categories";
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import  ProgrammeTTF from '../fonts/Programme-Regular.ttf';
import  ProgrammeBoldTTF from '../fonts/Programme-Bold.ttf';
export async function loader({params}){
    const supply = await getSupplys();
    const course = await getCourse(params.courseId);
    const bookings = await getBookings();
    const courseSupplys = supply.records.filter(supply=>supply.course_id===course.id);
    if(Object.keys(courseSupplys).length === 0){
      throw new Error("no supply for course");
    }
    const category = await getCategory(course.category_id);

    const courseBookings = bookings.records.filter(booking=>booking.course_id === course.id);
    const courseSupplysNotBooked = course.allow_multiple_timeslots==="1"?courseSupplys:courseSupplys.filter(supply=>!courseBookings.find(booking=>booking.date ===supply.date));
    const courseBlockBookings = bookings.records.filter(booking=>course.blocks.indexOf(booking.course_id)!==-1);
    const courseBookedDates = courseBlockBookings.map(a => a.date);
    const courseSupplyNotBookedNotBlocked = courseSupplysNotBooked.filter(supply=>courseBookedDates.indexOf(supply.date)===-1);
    if(Object.keys(courseSupplyNotBookedNotBlocked).length === 0){
      throw new Error("all booked");
    }
    return {course, category, supply, courseBookings, courseSupplyNotBookedNotBlocked}
}
export async function action({request}){
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    console.log(updates.time_slots);
    if(!Array.isArray(updates.time_slots)){
        let timeSlots =[];
        let newTimes = updates.time_slots.substring(0,updates.time_slots.length-1);
        console.log(newTimes);
        updates.time_slots.split(",").map((a)=>timeSlots.push(parseInt(a)));
        updates.time_slots = timeSlots;
    }
    await createBooking(updates);
    return redirect('/tak');
}

export default function Forloeb(){
    const data = useLoaderData();
    const course = data.course;
    const category = data.category;
    const courseSupplyNotBookedNotBlocked = data.courseSupplyNotBookedNotBlocked;
    const courseBookings = data.courseBookings;
    if(Object.keys(courseSupplyNotBookedNotBlocked).length === 0){
      throw new Error("all booked");
    }
    const [date,setDate]=useState("");
    const [paymentMethod,setPaymentMethod] = useState("");
    const [courseId]=useState(course.id);
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [school,setSchool] = useState("");
    const [grade,setGrade] = useState("");
    const [participants,setParticipants] = useState(1);
    const [comment,setComment] = useState("");
    const [timeSlots,setTimeSlots] = useState([0]);
    const [billable,setBillable] = useState(0);
    const [paymentDetails,setPaymentDetails] = useState("");
    const [step,setStep] = useState(1);
    const submit = useSubmit();
    const groupedDates = {};
    
    const daybookedSlots = (day)=> {
      let bookedTimeslots = [];
      let courseDayBookings = courseBookings.filter(booking=>booking.date ===day);
      
      if(Array.isArray(courseDayBookings)){
        courseDayBookings.map((booking)=>{
          booking.time_slots.map((slot)=>bookedTimeslots.push(slot))
          return true})
      }
      
      return bookedTimeslots;

    }
    courseSupplyNotBookedNotBlocked.map((supply)=>{let week=getWeek(new Date(supply.date));
      let year=getYear(new Date(supply.date));
    if(year+" uge "+week in groupedDates){
        groupedDates[year+" uge "+week].push(supply.date);
    }else{groupedDates[year+" uge "+week] =[supply.date]};
  return true});
    const dayNames = ["søn","man","tir","ons","tor","fre","lør"];
    const handleSubmitData = (event)=> {
        event.preventDefault();
        if(event.target.value==="nextStep"){
          setStep(step+1);
        }else if(event.target.value==="submitData"){
        let formData = new FormData();
        formData.append("course_id", courseId);
        formData.append("date", date);
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("school", school);
        formData.append("grade", grade);
        formData.append("participants", participants);
        formData.append("comment", comment);
        formData.append("time_slots", timeSlots);
        formData.append("billable", billable);
        formData.append("payment_details", paymentDetails);
        formData.append("payment_method", paymentMethod);
        submit(formData,{
            method: "post",
            encType: "application/x-www-form-urlencoded",
          });
        }
    }
    const convertDate = (date) => {
      let dt = new Date(date);
      let day =dt.getDate();
      if(day<10){day="0"+day}
      let month = dt.getMonth() + 1;
      if(month<10){month="0"+month}
      let year= dt.getFullYear();
      return `${day}-${month}-${year}`;
    };
    const customThemeFont = extendTheme({
      typography: {
        fontFamily:{
          display:'Programme',
          body: 'Programme',
          }

        },
        
        
        components:{
          JoyStep:{
            styleOverrides:{
              root:{
                fontFamily:'Programme',
              },
            },
          },
          JoyFormLabel :{
            styleOverrides:{
            root:{
            fontFamily:'Programme',
            },
          },
          },
          MuiCssBaseline:{
            styleOverrides: `
            @font-face {
              font-family: 'Programme';
              font-style: normal;
              font-display: swap;
              font-weight:400;
              src: local('Programme'), local('Programme-Regular'), url(${ProgrammeTTF}) format('ttf');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            },
            @font-face {
              font-family: ' Programme';
              font-style: normal;
              font-display: swap;
              font-weight:700;
              src: local('Programme'), local('Programme-Bold'), url(${ProgrammeBoldTTF}) format('ttf');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            `,
          },
        },
    })
    return(
      <CssVarsProvider theme={customThemeFont}>
        <CssBaseline />
        
      {/* must be used under CssVarsProvider */}
      
        <Box  display="flex"  flexDirection={"column"} alignItems="center">
            <Box display="flex" alignItems="start" flexDirection={"column"}><Typography sx={{m:1, fontFamily:'Programme'}} level="h2">Booking af {category.title}</Typography>
            <Typography sx={{m:1}} level="h3" fontFamily={"Programme"}> {course.title}</Typography>
            </Box>
            <Stack spacing={2}>
                <Stepper size="sm" sx={{maxWidth:"600px"}}>
                    <Step 
                    indicator={step===1?
                        <StepIndicator variant="solid" color="neutral">
                        1
                        </StepIndicator>:<StepIndicator>1</StepIndicator>
                    }
                    >
                    Dato og tid
                    </Step>
                    <Step  indicator={step===2?
                        <StepIndicator variant="solid" color="neutral">
                        2
                        </StepIndicator>:<StepIndicator>2</StepIndicator>}>Kontakt</Step>
                    <Step indicator={step===3?
                        <StepIndicator variant="solid" color="neutral">
                       3
                        </StepIndicator>:<StepIndicator>3</StepIndicator>}>Deltagere</Step>
                    <Step indicator={step===4?
                        <StepIndicator variant="solid" color="neutral">
                       4
                        </StepIndicator>:<StepIndicator>4</StepIndicator>}>kommentarer</Step>
                </Stepper>
            </Stack>
            {step===1 &&
            <Box sx={{m:1}} alignItems={"center"} width="345px"><FormControl sx={{m:2}}>
                <FormLabel><strong>Vælg en Dato for besøg</strong></FormLabel>
            <Select 
      placeholder="Vælg en ledig dato"
      defaultListboxOpen={true}
      slotProps={{
        listbox: {
          component: 'div',
          sx: {
            maxHeight: 340,
            overflow: 'auto',
            '--List-padding': '0px',
            '--ListItem-radius': '0px',
          },
        },
      }}
      sx={{ width: 240}}
      onChange={(event,newValue)=>{setTimeSlots([0]);setDate(newValue)}}
      value={date}
    >
      {Object.entries(groupedDates).map(([name, dates], index) => (
        <React.Fragment key={name}>
          {index !== 0 && <ListDivider role="none" />}
          <List
            aria-labelledby={`select-group-${name}`}
            sx={{ '--ListItemDecorator-size': '28px' }}
          >
            <ListItem key={name} id={`select-group-${name}`} sticky>
              <Typography level="body-xs" textTransform="uppercase">
                {name} ({dates.length})
              </Typography>
            </ListItem>
            {dates.map((date) => (
              <Option
                key={date}
                value={date}
                label={
                  <React.Fragment>
                    <Chip
                      size="sm"
                      sx={{ borderRadius: 'xs', mr: 1 }}
                    >
                      {name}
                    </Chip>{' '}
                    {convertDate(date)}
                  </React.Fragment>
                }
                sx={{
                  [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                    {
                      opacity: 1,
                    },
                }}
              >
                <ListItemDecorator sx={{ opacity: 0 }}>
                  <Check />
                </ListItemDecorator>
                {convertDate(date)}{' '}<Chip
                      size="sm"
                      sx={{ borderRadius: 'xs', mr: 1 }}
                    >{dayNames[getDay(new Date(date))]}</Chip>
              </Option>
            ))}
          </List>
        </React.Fragment>
      ))}
    </Select></FormControl>{ date && course.time_slots.length<2 && <Typography sx={{m:2}}>Tid: {course.time_slots[0]}</Typography>}{date && course.time_slots.length>1 && <FormControl sx={{m:2}}><FormLabel>Tidspunkt</FormLabel><Select value={course.allow_multiple_timeslots==="1"?timeSlots:timeSlots[0]} multiple={course.allow_multiple_timeslots==="0"?false:true} onChange={(e,newValue)=>{course.allow_multiple_timeslots==="1"?setTimeSlots(newValue):setTimeSlots([newValue])}}>{course.time_slots.map((slot,index)=>!(index in daybookedSlots(date))?<Option disabled={index in daybookedSlots(date)} key={index} value={index}>{slot}</Option>:"")}</Select></FormControl>}<Button sx={{m:2}} value="nextStep" onClick={(event)=>handleSubmitData(event)}>Næste</Button></Box>}
    {step===2 &&<Box width="325px">
      <FormControl sx={{m:2}}>
        <FormLabel>Navn</FormLabel>
        <Input type="text" value={name} onChange={(e)=>setName(e.target.value)}/>
        </FormControl>
        <FormControl sx={{m:2}}>
          <FormLabel>Telefon</FormLabel>
          <Input type="tel" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
        </FormControl>
        <FormControl sx={{m:2}}>
          <FormLabel>Email</FormLabel>
          <Input type="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
        </FormControl>
        <Button sx={{m:2}} value="nextStep" onClick={(event)=>handleSubmitData(event)}>Næste</Button>
        </Box>}
        {step===3 && <Box width="325px">
          <FormControl sx={{m:2}}>
            <FormLabel>Skole</FormLabel>
            <Input type="text" value={school} onChange={(e)=>setSchool(e.target.value)}/>
          </FormControl>
          <FormControl sx={{m:2}}>
            <FormLabel>Klassetrin</FormLabel>
            <Input type="text" value={grade} onChange={(e)=>setGrade(e.target.value)}/>
          </FormControl>
          <FormControl sx={{m:2}}>
            <FormLabel>Antal elever/deltagere</FormLabel>
            <Input type="number" color={participants>28 ?"warning":"neutral"} value={participants} onChange={(e)=>setParticipants(e.target.value)}/>
          </FormControl>
          {participants>28 && <FormHelperText>Forløbet er beregnet til maksimalt 28 elever</FormHelperText>}
          {course.price>0 && <FormControl sx={{m:2}}>
            <FormLabel>Ligger skolen udenfor Aalborg kommune?</FormLabel>
            <Checkbox checked={billable==="1"?true:false} value={billable} onChange={(e)=>setBillable(e.target.checked?"1":"0")}/>
          </FormControl>}
          {billable==="1" &&  course.price>0 && <FormControl>
        <FormLabel>Betaling</FormLabel>
        <RadioGroup
          orientation="horizontal"
          value={paymentMethod}
          onChange={(event) => setPaymentMethod(event.target.value)}
        >
          <Radio value="ean" label="via EAN faktura" />
          <Radio value="email" label="faktura på mail" />
        </RadioGroup>
      </FormControl>}
      {billable==="1" && course.price>0 && <FormControl sx={{m:2}}><FormLabel>{paymentMethod==="ean"?"EAN Nummer":"Angiv evt. CVR"}</FormLabel><Input name="payment_details" value={paymentDetails} onChange={(e)=>setPaymentDetails(e.target.value)}/></FormControl>}
          <Button value="nextStep" onClick={(event)=>handleSubmitData(event)} sx={{m:2}}>Næste</Button>
          </Box>}
          {step===4 && <Box width="325px">
            <Sheet sx={{m:2, p:2}} variant="soft">
              <Grid container spacing={2} sx={{ flexGrow: .5 }}>
                <Grid xs={10}>
              <List sx={{'--ListItem-minHeight':'0px', '--ListItem-paddingY':'0px'}}>
                <ListItem nested >
                <ListSubheader >Forløb</ListSubheader>
                  <List sx={{fontSize:12, '--ListItem-minHeight':'0px', '--ListItem-paddingY':'0px'}}>
                    <ListItem >Forløb: {course.title}</ListItem>
                    <ListItem >Dato: {date}</ListItem>
                    <ListItem>Tid: {timeSlots.map((slot)=>  {return " " +course.time_slots[slot]})}</ListItem>
                    <ListItem >Pris: {course.price>0 && billable?course.price+ " kr.":"gratis"}</ListItem>
                  </List>
                </ListItem>
                </List></Grid><Grid xs={10}><List sx={{'--ListItem-minHeight':'0px', '--ListItem-paddingY':'0px'}}>
                <ListItem nested>
                <ListSubheader >Deltagere</ListSubheader>
                  <List sx={{fontSize:12, '--ListItem-minHeight':'0px', '--ListItem-paddingY':'0px'}}>
                    <ListItem >Skole: {school}</ListItem>
                    <ListItem >Klasse: {grade}</ListItem>
                    <ListItem >Deltagere: {participants}</ListItem>
                    {course.price>0 &&<ListItem >Aalborg Kommune Skole: {billable?"nej":"ja"}</ListItem>}
                    {course.price>0 && billable?<ListItem>Betalings info: {paymentDetails}</ListItem>:""}
                  </List>
                </ListItem>
              </List></Grid><Grid xs={10}><List sx={{'--ListItem-minHeight':'0px', '--ListItem-paddingY':'0px'}}>
                <ListItem nested>
                <ListSubheader >Kontakt</ListSubheader>
                  <List sx={{fontSize:12, '--ListItem-minHeight':'0px', '--ListItem-paddingY':'0px'}}>
                    <ListItem >Navn: {name}</ListItem>
                    <ListItem >Telefon: {phone}</ListItem>
                    <ListItem >Email: {email}</ListItem>
                  </List>
                </ListItem></List></Grid>
              </Grid>
            </Sheet>
            <FormControl sx={{m:2}}>
              <FormLabel>Kommentarer</FormLabel>
              <Input type="text" value={comment} onChange={(e)=>setComment(e.target.value)}/>
              <FormHelperText>Har du nogle kommentarer i forhold til din booking? Det kan f.eks. være ændring af tidspunkt, særlige behov i klassen, eller ønske om dialog med underviser</FormHelperText>
              </FormControl>
              <Button sx={{ml:2}} value="submitData" onClick={(event)=>handleSubmitData(event)}>Indsend booking</Button></Box>}
        </Box>
        

      {/* The rest of your application */}
    </CssVarsProvider>
    )
    
    
}