import { Box, Button, Checkbox, FormControl, FormLabel, Input, Option, Radio, RadioGroup, Select } from "@mui/joy";
import { Form, redirect, useLoaderData } from "react-router-dom";
import { createBooking } from "../Bookings";
import { getCourses } from "../Courses";
import { useState } from "react";

export async function action({request}){
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    if(!Array.isArray(updates.time_slots)){
        let timeSlots =[];
        let newTimes = updates.time_slots.substring(1,updates.time_slots.length-1);
        
        newTimes.split(",").map((a)=>timeSlots.push(parseInt(a)));
    updates.time_slots = timeSlots;
    }
    await createBooking(updates);
    return redirect('/administration/bookings');
}

export async function loader(){
    const Courses = await getCourses();


    return{Courses}
}
export default function NewBooking(){
    const data = useLoaderData();
    const Courses = data.Courses.records;
    const [course,setCourse] = useState(Courses[0]);
    const [multiTime,setMultiTime] = useState(false);
    const [timeSlotIndexes,setTimeSlotIndexes] = useState(0);
    const [billable,setBillable] =useState();
    const [paymentMethod,setPaymentMethod] = useState();
    const handleCourse= (event,newValue)=>{
        let course = Courses.find((course)=>course.id===newValue);
        setCourse(course);
        if(course.allow_multiple_timeslots>0){
            setTimeSlotIndexes([]);
            setMultiTime(true);
        }else{
            setMultiTime(false);
        }
        if(course.time_slots.length<2){
            setTimeSlotIndexes(0);
        }
    }
    

    return (
        <Box>
            <Form method="post">
                <FormControl required>
                    <FormLabel>Dato</FormLabel>
                    <Input type="date" name="date"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Navn</FormLabel>
                    <Input name="name"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Email adresse</FormLabel>
                    <Input type="email" name="email"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Telefon</FormLabel>
                    <Input type="tel" name="phone"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Skole</FormLabel>
                    <Input name="school"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Klasse</FormLabel>
                    <Input name="grade"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Antal Elever</FormLabel>
                    <Input name="participants"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Kommentarer</FormLabel>
                    <Input name="comment"/>
                </FormControl>
                <FormControl required>
                    <FormLabel>Forløb</FormLabel>
                    <Select onChange={handleCourse} value={course.id} name="course_id"> 
                        {Courses.map((cou)=><Option key={cou.id} value={cou.id}>{cou.title}</Option>)}
                    </Select>
                </FormControl>
                <FormControl required>
                    <FormLabel>Tidspunkt</FormLabel>
                    <Select defaultValue={0} value={timeSlotIndexes} onChange={(event,newValue)=>setTimeSlotIndexes(newValue)} multiple={multiTime?true:false} name="time_slots">
                        {course.time_slots.map((timeslot,index)=>{return<Option value={index} key={index}>{timeslot}</Option>})}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Faktura</FormLabel>
                    <Checkbox label="skal faktureres" value={billable} checked={billable?true:false} onChange={(e)=>{setBillable(e.target.checked?1:0)}} name="billable"/>
                </FormControl>
                <FormControl>
        <FormLabel>Betaling</FormLabel>
        <RadioGroup
        name="payment_method"
          orientation="horizontal"
          value={paymentMethod}
          onChange={(event) => setPaymentMethod(event.target.value)}
        >
          <Radio value="ean" label="via EAN faktura" />
          <Radio value="email" label="faktura på mail" />
        </RadioGroup>
      </FormControl>
                <FormControl>
                    <FormLabel>Betalings info</FormLabel>
                    <Input name="payment_details"/>
                </FormControl>
                <FormControl><Button type="submit">Opret Booking</Button></FormControl>
            </Form>
        </Box>
    )
}