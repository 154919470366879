import { Form, redirect, useLoaderData } from "react-router-dom";
import { Box, Button, Sheet, Table, Typography } from "@mui/joy";
import { deleteBooking, getBookings } from "../Bookings";
import { getCourses } from "../Courses";
export async function loader(){
    const Bookings = await getBookings();
    const Courses = await getCourses();
    return {Bookings, Courses}
}
export async function action({request}){
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    await deleteBooking(updates);
    return redirect('/administration/bookings');
}   
export default function Bookings(){
    const data = useLoaderData();
    const courses = data.Courses.records;
    const bookings = data.Bookings.records;
    return(
        <Box>
        <Typography level="h1">Bookinger</Typography>
        <Sheet sx={{ height: 700, overflow: 'auto' }}>
                <Table stickyHeader>
                    <thead>
                        <tr>
                            <th>Dato</th>
                            <th>Forløb</th>
                            <th>Navn</th>
                            <th>Email</th>
                            <th>Skole</th>
                            <th>Handlinger</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(bookings)?bookings.map((booking)=>{return(
                        <tr key={booking.id}>
                            <td>{booking.date}</td>
                            <td>{courses.find(course=>course.id===booking.course_id).title}</td>
                            <td>{booking.name}</td>
                            <td>{booking.email}</td>
                            <td>{booking.school}</td>
                            <td><Box sx={{display:"flex",gap:"1rem"}}><Button component="a" href={"/administration/editBooking/"+booking.id}>rediger</Button><Form method="post"><input type="hidden" name="id" value={booking.id}/><Button type="submit">slet</Button></Form></Box></td>
                        </tr>)}):<Typography>ingen bookinger</Typography>}
                    </tbody>
                </Table>
            </Sheet>
            <Button component="a" href="/administration/newBooking">Ny booking</Button>
            </Box>
    );
}