import Done from "@mui/icons-material/Done";
import { Box, Button, Checkbox, FormControl, FormHelperText, FormLabel, Input, List, ListItem, Option, Select, Typography } from "@mui/joy";
import { useState } from "react";
import { Form, redirect, useLoaderData } from "react-router-dom";
import { getCourse, getCourses, updateCourse } from "../Courses";
import { getCategories } from "../Categories";

export async function loader({params}){
    const course = await getCourse(params.courseId);
    const courses = await getCourses();
    const Categories = await getCategories();

    return{course,courses, Categories};
}
export async function action({request}){
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    await updateCourse(updates);
    return redirect('/administration/courses');

}

export default function EditCourse(){
    const data= useLoaderData();
    const Categories = data.Categories.records;
    const course = data.course;
    const Courses = data.courses.records.filter(cour=>cour.id!==course.id);
    const [blocks,setBlocks]=useState(course.blocks);
    const [allowMultipleTimeslots,setAllowMultipleTimeslots] = useState(course.allow_multiple_timeslots);
    const [title,setTitle] = useState(course.title);
    const [shortName,setShortName] = useState(course.short_name);
    const [categoryId,setCategoryId] = useState(course.category_id);
    const [timeslots,setTimeSlots] = useState(course.time_slots);
    const [price,setPrice] = useState(course.price);
    const [link,setLink] = useState(course.link);
    const handleNewCatId = (event,newValue)=>{
        setCategoryId(newValue);
    }
    return(
    <Box>
    <Typography>Rediger Forløb</Typography>
    <Form method="post">
        <input type="hidden" name="id" value={course.id}/>
        <FormControl>
            <FormLabel>Titel</FormLabel>
            <Input name="title" value={title} onChange={(e)=>setTitle(e.target.value)}/>
        </FormControl>
        <FormControl>
            <FormLabel>Kort navn</FormLabel>
            <Input name="short_name"  value={shortName} onChange={(e)=>setShortName(e.target.value)}/>
        </FormControl>
        <FormControl>
            <FormLabel>Kategori</FormLabel>
            <Select name="category_id" value={categoryId} onChange={handleNewCatId}>
                {Array.isArray(Categories)?Categories.map((category)=>{return(<Option key={category.id} value={category.id}>{category.title}</Option>)}):<Option value={""}>ingen kategori</Option>}
                </Select>
        </FormControl>
        {Array.isArray(Courses)?<FormControl>
            <FormLabel>Blokerer</FormLabel>
            <List
            orientation="horizontal"
            wrap
            sx={{
            '--List-gap': '8px',
              '--ListItem-radius': '20px',
              '--ListItem-minHeight': '32px',
              '--ListItem-gap': '4px',
            }}>
            {Courses.map(
                (course,index)=>(
                <FormControl key={course.id}><ListItem key={course.id}>
                    {blocks.indexOf(course.id)!==-1 && (
                        <Done
                            fontSize="md"
                            color="primary"
                            sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                        />
                    )}
                  <Checkbox 
                  size="sm"
                  disableIcon
                  overlay
                  label={course.title}
                  checked={blocks.indexOf(course.id)!==-1}
                  variant={blocks.indexOf(course.id)!==-1? 'soft' : 'outlined'}
                  onChange={(event)=>{
                    if(event.target.checked){
                        setBlocks((val)=> [...val,course.id]);
                    }else {
                        setBlocks(blocks.filter((val)=>val!== course.id));
                    }
                  }}
                  slotProps={{
                    action:({checked})=>({
                        sx: checked
                        ? {
                            border: '1px solid',
                            borderColor: 'primary.500',
                        }
                        :{},
                    }),
                  }}
                  />
                  </ListItem></FormControl>
                  ),
                  )}
                  </List>
            <input type="hidden" value={blocks} name="blocks"/>
        </FormControl>:<FormControl><Typography>Blokerer</Typography><Typography>ingen andre forløb oprettet</Typography><input type="hidden" value={blocks} name="blocks"/></FormControl>}        
        <FormControl>
            <FormLabel>Tidspunkter</FormLabel>
            <FormHelperText>Adskild tidsrum med komma eks. 10.00-13.00,13.00-17.00,8.00-10.00</FormHelperText>
            <Input name="time_slots" value={timeslots} onChange={(e)=>setTimeSlots(e.target.value)}/>
        </FormControl>
        <FormControl>
            <FormLabel>Tillad booking af flere tidspunkter</FormLabel>
            <Checkbox checked={allowMultipleTimeslots==="0"?false:true} value={allowMultipleTimeslots} name="allow_multiple_timeslots" onChange={(e)=>setAllowMultipleTimeslots(e.target.checked?"1":"0")}/>
        </FormControl>
        <input type="hidden" name="allow_multiple_timeslots" value={allowMultipleTimeslots}/>
        <FormControl>
            <FormLabel>Pris</FormLabel>
            <Input type="number" value={price} onChange={(e)=>setPrice(e.target.value)} name="price"/>
        </FormControl>
        <FormControl>
            <FormLabel>Link</FormLabel>
            <Input value={link} onChange={(e)=>setLink(e.target.value)} name="link"/>
        </FormControl>
        <FormControl orientation="horizontal" sx={{marginTop:"2rem",width: 300, justifyContent: 'space-between'}}>
            <Button type="submit">Opdater Forløb</Button>
        </FormControl>
    </Form>
    </Box>
    )
}