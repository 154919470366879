import { useLoaderData } from "react-router-dom";
import { Box, Button, List, ListItem, Typography } from "@mui/joy";
import { getCategories } from "../Categories";
export async function loader(){
    const Categories = await getCategories();
    return {Categories}
}   
export default function Categories(){
    const data = useLoaderData();
    const categories = data.Categories.records
    return(
        <Box display="flex" flexDirection="column" gap="2rem">
        <Typography level="h1">Kategorier</Typography>
        <List>
            {Array.isArray(categories)?categories.map((category)=>{return(<ListItem key={category.id} component="a" href={"/administration/editCategory/"+category.id}>{category.title}</ListItem>)}):<ListItem>ingen kategorier</ListItem>}
        </List>
        <Button component="a" href="/administration/newCategory">Opret kategori</Button>
        </Box>
    );
}