function getCookie(cname){
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' '){
          c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}
export async function getCourses(){
    const response = await fetch(
        `https://api.uc-booking.dk/course/read.php`,{method: 'POST',}
      );
      return await response.json();
    };
export async function getCourse(courseId){
    const response = await fetch(
        `https://api.uc-booking.dk/course/readOne.php`,{method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id:courseId,
          })});
      return await response.json();
}
export async function createCourse(courseDetails){
  const response = await fetch(`https://api.uc-booking.dk/course/create.php`,
  {method: 'POST',
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      title:courseDetails.title,
      short_name:courseDetails.short_name,
      category_id:courseDetails.category_id,
      blocks:courseDetails.blocks===""?[]:courseDetails.blocks.split(","),
      allow_multiple_timeslots:courseDetails.allow_multiple_timeslots,
      time_slots:courseDetails.time_slots.split(","),
      price:courseDetails.price,
      link:courseDetails.link,
      jwt:getCookie("jwt")
    })})
  return await response.json();

}
export async function updateCourse(courseDetails){
  const response = await fetch(`https://api.uc-booking.dk/course/update.php`,
  {method: 'POST',
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id:courseDetails.id,
      title:courseDetails.title,
      short_name:courseDetails.short_name,
      category_id:courseDetails.category_id,
      blocks:courseDetails.blocks===""?[]:courseDetails.blocks.split(","),
      allow_multiple_timeslots:courseDetails.allow_multiple_timeslots,
      time_slots:courseDetails.time_slots.split(","),
      price:courseDetails.price,
      link:courseDetails.link,
      jwt:getCookie("jwt")
    })})
  return await response.json();
}