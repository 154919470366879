import { Box, Button, Checkbox, FormControl, FormLabel, Input, Option, Radio, RadioGroup, Select } from "@mui/joy";
import { Form, redirect, useLoaderData } from "react-router-dom";
import { getBooking, updateBooking } from "../Bookings";
import { getCourses } from "../Courses";
import { useState } from "react";

export async function loader({params}){
    const Booking = await getBooking(params.bookingId);
    const Courses = await getCourses();
    return {Booking,Courses}
}
export async function action({request}){
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    if(!Array.isArray(updates.time_slots)){
        let timeSlots =[];
        let newTimes = updates.time_slots.substring(1,updates.time_slots.length-1);
        
        newTimes.split(",").map((a)=>timeSlots.push(parseInt(a)));
    updates.time_slots = timeSlots;
    }
    await updateBooking(updates);
    return redirect('/administration/bookings');
}
export default function EditBooking(){

    const data = useLoaderData();
    const Courses = data.Courses.records;
    const Booking = data.Booking;
    const [date,setDate] = useState(Booking.date);
    const [name,setName] = useState(Booking.name);
    const [email,setEmail] = useState(Booking.email);
    const [phone,setPhone] = useState(Booking.phone);
    const [school,setSchool] = useState(Booking.school);
    const [grade,setGrade] = useState(Booking.grade);
    const [paymentDetails,setPaymentDetails] = useState(Booking.payment_details);
    const [paymentMethod,setPaymentMethod] = useState(Booking.payment_method);
    const [participants,setParticipants] = useState(Booking.participants);
    const [comment,setComment] = useState(Booking.comment); 
    const [course,setCourse] = useState(Courses.find((course)=>course.id===Booking.course_id));
    //const [courseId,setCourseId] = useState(Booking.course_id);
    const [multiTime,setMultiTime] = useState(course.allow_multiple_timeslots);
    const [timeSlotIndexes,setTimeSlotIndexes] = useState(Booking.time_slots);
    const [billable,setBillable] =useState(Booking.billable);

    const handleCourse= (event,newValue)=>{
        let course = Courses.find((course)=>course.id===newValue);
        setCourse(course);
        if(course.allow_multiple_timeslots>0){
            setTimeSlotIndexes([]);
            setMultiTime(true);
        }else{
            setMultiTime(false);
        }
        if(course.time_slots.length<2){
            setTimeSlotIndexes(0);
        }
    }

    return (
        <Box>
            <Form method="post">
                <FormControl>
                    <FormLabel>Dato</FormLabel>
                    <Input type="date" name="date" value={date} onChange={(e)=>setDate(e.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Navn</FormLabel>
                    <Input name="name" value={name} onChange={(e)=>setName(e.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Email adresse</FormLabel>
                    <Input type="email" name="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Telefon</FormLabel>
                    <Input type="tel" name="phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Skole</FormLabel>
                    <Input name="school" value={school} onChange={(e)=>setSchool(e.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Klasse</FormLabel>
                    <Input name="grade" value={grade} onChange={(e)=>setGrade(e.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Antal Elever</FormLabel>
                    <Input name="participants" value={participants} onChange={(e)=>setParticipants(e.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Kommentarer</FormLabel>
                    <Input name="comment" value={comment} onChange={(e)=>setComment(e.target.value)}/>
                </FormControl>
                <FormControl>
                    <FormLabel>Forløb</FormLabel>
                    <Select onChange={handleCourse} value={course.id} name="course_id"> 
                        {Courses.map((cou)=><Option key={cou.id} value={cou.id}>{cou.title}</Option>)}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Tidspunkt</FormLabel>
                    <Select defaultValue={0} value={timeSlotIndexes} onChange={(event,newValue)=>setTimeSlotIndexes(newValue)} multiple={multiTime?true:false} name="time_slots">
                        {course.time_slots.map((timeslot,index)=>{return<Option value={index} key={index}>{timeslot}</Option>})}
                    </Select>
                </FormControl>
                <FormControl>
                    <FormLabel>Faktura</FormLabel>
                    <Checkbox label="skal faktureres" name="billable" value={billable} checked={billable===1?true:false} onChange={(e)=>{setBillable(e.target.checked?1:0)}} />
                </FormControl>
                <FormControl>
                <FormLabel>Betaling</FormLabel>
        <RadioGroup
        name="payment_method"
          orientation="horizontal"
          value={paymentMethod}
          onChange={(event) => setPaymentMethod(event.target.value)}
        >
          <Radio value="ean" label="via EAN faktura" />
          <Radio value="email" label="faktura på mail" />
        </RadioGroup>
      </FormControl>
                <FormControl>
                    <FormLabel>betalings info</FormLabel>
                    <Input name="payment_details" value={paymentDetails} onChange={(e)=>setPaymentDetails(e.target.value)}/>
                </FormControl>
                <input type="hidden" name="billable" value={billable}/>
                <input type="hidden" name="id" value={Booking.id} />
                <FormControl><Button type="submit">Opret Booking</Button></FormControl>
            </Form>
        </Box>
    )
}