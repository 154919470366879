import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import '@fontsource/inter';
import Root, {loader as RootLoader} from "./routes/root";
import Login, {action as loginAction} from "./routes/Login";
import Administration, {loader as AdministrationLoader} from "./routes/adminstration";
import Users, {action as deleteUserAction,loader as usersloader} from "./routes/users";
import Bookings, {loader as bookingsloader, action as deleteBookingAction} from "./routes/bookings";
import Courses, {loader as coursesloader} from "./routes/courses";
import Categories, {loader as categoriesloader} from "./routes/categories";
import Supply, {action as supplyAction, loader as supplyloader} from "./routes/supply";
import NewUser, {action as newUserAction} from "./routes/newUser";
import EditUser, {action as editUserAction, loader as editUserloader} from "./routes/editUser";
import NewCategory, {action as newCategoryAction} from "./routes/newCategory";
import NewCourse, {action as newCourseAction, loader as newCourseloader}from "./routes/newCourse";
import EditCourse, {action as editCourseAction, loader as editCourseloader} from "./routes/editCourse";
import EditCategory, {action as editCategoryAction, loader as editCategoryloader} from "./routes/editCategory";
import NewBooking, {action as newBookingAction, loader as newBookingloader}from "./routes/newBooking";
import EditBooking, {action as editBookingAction, loader as editBookingloader} from "./routes/editBooking";
import Forloeb, {action as courseBookingAction, loader as courseLoaderAction} from "./routes/forloeb";
import BookingErrors from "./routes/bookingErrors";
import Tak from "./routes/tak";
import Index from "./routes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader:RootLoader,
    children:[
      {
        path: "login",
        action:loginAction,
        element: <Login />,
        
      },
      {
        path: "loggedOut",
        action:loginAction,
        element:<Login/>,
      },
      {index:true,
      element:<Index/>}
    ],

  },
      {
        path: "forloeb/:courseId",
        action: courseBookingAction,
        loader: courseLoaderAction,
        errorElement:<BookingErrors />,
        element:<Forloeb/>
      },
      {
        path: "tak",
        element:<Tak/>
      },
  {
    path: "administration",
    loader:AdministrationLoader,
    element:<Administration/>,
    children:[
      {
        path: "users",
        loader: usersloader,
        action: deleteUserAction,
        element: <Users />,
      },
      {
        path: "newUser",
        action: newUserAction,
        element: <NewUser />,
      },
      {
        path: "editUser/:userId",
        loader:editUserloader,
        action: editUserAction,
        element: <EditUser />,
      },
      {
        path: "courses",
        loader: coursesloader,
        element: <Courses />,
      },
      {
        path: "newCourse",
        loader: newCourseloader,
        action: newCourseAction,
        element: <NewCourse />,
      },
      {
        path: "editCourse/:courseId",
        loader: editCourseloader,
        action: editCourseAction,
        element: <EditCourse />,
      },
      {
        path: "bookings",
        loader: bookingsloader,
        action: deleteBookingAction,
        element: <Bookings />,
      },
      {
        path: "newBooking",
        loader: newBookingloader,
        action: newBookingAction,
        element: <NewBooking />,
      },
      {
        path: "editBooking/:bookingId",
        loader: editBookingloader,
        action: editBookingAction,
        element: <EditBooking />,
      },
      {
        path: "categories",
        loader: categoriesloader,
        element: <Categories />,
      },
      {
        path: "newCategory",
        action: newCategoryAction,
        element: <NewCategory />,
      },
      {
        path: "editCategory/:categoryId",
        action: editCategoryAction,
        loader: editCategoryloader,
        element: <EditCategory />,
      },
      {
        path: "supply",
        loader: supplyloader,
        action: supplyAction,
        element: <Supply />,
      }
    ]
  }
],{basename:process.env.PUBLIC_URL});
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode><RouterProvider router={router} /></React.StrictMode>
);