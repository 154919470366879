function getCookie(cname){
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' '){
            c = c.substring(1);
        }
  
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }
  export async function getUsers(){
    let jwt = getCookie("jwt");
      const response = await fetch(
          `https://api.uc-booking.dk/user/read.php`,{method: 'POST',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            jwt:jwt,
          })
          }
        );
        return await response.json();
      };
  
  export async function getUser(usrId){
    let jwt = getCookie("jwt");
    const response = await fetch(
      `https://api.uc-booking.dk/user/readOne.php`,{method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id:usrId,
          jwt:jwt
        })});
    return await response.json();
  };
  export async function createUser(usrDetails){
    let jwt = getCookie("jwt");
    const response = await fetch(`https://api.uc-booking.dk/user/create.php`,
    {method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
                name:usrDetails.name,
                password:usrDetails.password,
                email:usrDetails.email,
        jwt:jwt
      })})
    return await response.json();
  
  }
  export async function updateUser(usrDetails){
    let jwt = getCookie("jwt");
      const response = await fetch(`https://api.uc-booking.dk/user/update.php`,
          {method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id:usrDetails.id,
              name:usrDetails.name,
                password:usrDetails.password,
                email:usrDetails.email,
              jwt:jwt
            })})
    return await response.json();
  }
  export async function deleteUser(usrDetails){
    let jwt = getCookie("jwt");
    const response = await fetch(`https://api.uc-booking.dk/user/delete.php`,
          {method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id:usrDetails.id,
              jwt:jwt
            })})
            return await response.json();
  }
  export async function loginUser(usrDetails) {
    const response = await fetch(`https://api.uc-booking.dk/user/login.php`,
    {method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password:usrDetails.password,
        email:usrDetails.email,
      })})
    return await response.json();

  }