function getCookie(cname){
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' '){
          c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}
export async function getCategories(){
    const response = await fetch(
        `https://api.uc-booking.dk/category/read.php`,{method: 'POST',}
      );
      return await response.json();
    };

export async function getCategory(catId){
  const response = await fetch(
    `https://api.uc-booking.dk/category/readOne.php`,{method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id:catId,
      })});
  return await response.json();
};
export async function createCategory(catDetails){
  let jwt = getCookie("jwt");
  const response = await fetch(`https://api.uc-booking.dk/category/create.php`,
  {method: 'POST',
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      title:catDetails.title,
      jwt:jwt
    })})
  return await response.json();

}
export async function updateCategory(catDetails){
  let jwt = getCookie("jwt");
    const response = await fetch(`https://api.uc-booking.dk/category/update.php`,
        {method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id:catDetails.id,
            title:catDetails.title,
            jwt:jwt
          })})
  return await response.json();
}
export async function deleteCategory(catDetails){
  let jwt = getCookie("jwt");
  const response = await fetch(`https://api.uc-booking.dk/category/delete.php`,
        {method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id:catDetails.id,
            jwt:jwt
          })})
          return await response.json();
}