import { redirect, useLoaderData } from "react-router-dom";
import { Box, Grid, Sheet, Typography } from "@mui/joy";
import { getBookings } from "../Bookings";
import { getCategories } from "../Categories";
import { getCourses } from "../Courses";
import { createSupply, deleteSupply, getSupplys } from "../Supply";
import SupplyDayList from "../SupplyDayList";
import { useState } from "react";
import HonestWeekPicker from "../HonestWeekPicker";
import { addDays, isPast } from "date-fns";
export async function loader(){
    const Bookings = await getBookings();
    const Categories = await getCategories();
    const Courses = await getCourses();
    const Supply = await getSupplys();
    return {Bookings,Categories,Courses,Supply}
}
export async function action({request}) {
    const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        if(updates.supplyAction==="create"){
    await createSupply(updates);
        }else{
          await deleteSupply(updates);
        }
    return redirect(`/administration/supply`);
  }   
export default function Supply(){
    const data = useLoaderData();
    const categories = data.Categories.records;
    const courses = data.Courses.records;
    const supplys = data.Supply.records;
    const bookings = data.Bookings.records;
    const [week, setWeek] = useState({ firstDay: "02-02-2022" });
    const onChange = (week) => {
      setWeek(week);
    };
    const convertMsqlDate = (date) => {
        let dt = new Date(date);
        let day =dt.getDate();
        if(day<10){day="0"+day}
        let month = dt.getMonth() + 1;
        if(month<10){month="0"+month}
        let year= dt.getFullYear();
        return `${year}-${month}-${day}`;
      };
    const convertDate = (date) => {
        let dt = new Date(date);
        let day = dt.getDate();
        if(day<10){day="0"+day}
        let month = dt.getMonth() + 1;
        if(month<10){month="0"+month}
        let year= dt.getFullYear();
        
        let dayOfWeek = dt.getDay();
        let weekDays = ["Søndag","Mandag","Tirsdag","Onsdag","Torsdag","Fredag","Lørdag"];
        return `${weekDays[dayOfWeek]} d. ${day} /${month} - ${year}`;

    }
    const days=[convertMsqlDate(addDays(week.firstDay,1)),convertMsqlDate(addDays(week.firstDay,2)),convertMsqlDate(addDays(week.firstDay,3)),convertMsqlDate(addDays(week.firstDay,4))];
    return(
        <Box sx={{width:"100%"}}>
        <Typography level="h1">Udbud</Typography>
        <HonestWeekPicker onChange={onChange}/>
        <Grid container spacing={2} sx={{flexGrow:1,width:"100%"}}>
            <Grid xs={3}>
            {isPast(addDays(week.firstDay,1))?<Sheet variant="outlined" sx={{p:4,backgroundColor:"burlywood"}}>
            <Typography variant="solid" sx={{p:2}}>{convertDate(addDays(week.firstDay,1))}</Typography><Typography color="warning" variant="solid" sx={{m:2,p:4}}>Der kan ikke udbydes i fortiden</Typography></Sheet>:
            <SupplyDayList date={days[0]} name={convertDate(addDays(week.firstDay,1))} courses={courses} categories={categories} dayBookings={Array.isArray(bookings)?bookings.filter(booking=>booking.date ===days[0]):[]} daySupply={Array.isArray(supplys)?supplys.filter(supply=>supply.date ===days[0]):[]}/>}
            </Grid>
            <Grid xs={3}>
            {isPast(addDays(week.firstDay,2))?<Sheet variant="outlined" sx={{p:4,backgroundColor:"burlywood"}}>
            <Typography variant="solid" sx={{p:2}}>{convertDate(addDays(week.firstDay,2))}</Typography><Typography color="warning" variant="solid" sx={{m:2,p:4}}>Der kan ikke udbydes i fortiden</Typography></Sheet>:
            <SupplyDayList date={days[1]} name={convertDate(addDays(week.firstDay,2))} courses={courses} categories={categories} dayBookings={Array.isArray(bookings)?bookings.filter(booking=>booking.date ===days[1]):[]} daySupply={Array.isArray(supplys)?supplys.filter(supply=>supply.date ===days[1]):[]}/>}
            </Grid>
            <Grid xs={3}>
            {isPast(addDays(week.firstDay,3))?<Sheet variant="outlined" sx={{p:4,backgroundColor:"burlywood"}}>
            <Typography variant="solid" sx={{p:2}}>{convertDate(addDays(week.firstDay,3))}</Typography><Typography color="warning" variant="solid" sx={{m:2,p:4}}>Der kan ikke udbydes i fortiden</Typography></Sheet>:
            <SupplyDayList date={days[2]} name={convertDate(addDays(week.firstDay,3))} courses={courses} categories={categories} dayBookings={Array.isArray(bookings)?bookings.filter(booking=>booking.date ===days[2]):[]} daySupply={Array.isArray(supplys)?supplys.filter(supply=>supply.date ===days[2]):[]}/>}
            </Grid>
            <Grid xs={3}>
            {isPast(addDays(week.firstDay,4))?<Sheet variant="outlined" sx={{p:4,backgroundColor:"burlywood"}}>
            <Typography variant="solid" sx={{p:2}}>{convertDate(addDays(week.firstDay,4))}</Typography><Typography color="warning" variant="solid" sx={{m:2,p:4}}>Der kan ikke udbydes i fortiden</Typography></Sheet>:
            <SupplyDayList date={days[3]} name={convertDate(addDays(week.firstDay,4))} courses={courses} categories={categories} dayBookings={Array.isArray(bookings)?bookings.filter(booking=>booking.date ===days[3]):[]} daySupply={Array.isArray(supplys)?supplys.filter(supply=>supply.date ===days[3]):[]}/>}
            </Grid>
        </Grid>
        </Box>
    );
}