import { Box, Button, FormControl, FormLabel, Input, Typography } from "@mui/joy";
import { Form, redirect } from "react-router-dom";
import { createUser } from "../Users";

export async function action({request}){
    const formData = await request.formData();
        const updates = Object.fromEntries(formData);
        await createUser(updates);
        return redirect('/administration/users');
}

export default function NewUser(){
    return(
        <Box display="flex" gap="2rem">
            <Typography level="h1">Opret ny bruger</Typography>
            <Form method="post">
                <FormControl>
                    <FormLabel>Navn</FormLabel>
                    <Input name="name" />
                </FormControl>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" name="email" />
                </FormControl>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" name="password"/>
                </FormControl>
                <FormControl>
                    <Button type="submit">Opret Bruger</Button>
                </FormControl>
            </Form>
        </Box>
    )
}